import * as React from 'react';
import Card from "./card"
import MiniView from "./miniView"
import "../../style/slider.scss"
import Img from "../defaults/image"
import Animated from "../animated";

const arrow = require("../../images/arrow-right.png")
const whiteArrow = require("../../images/arrow-right2.png")

const Slider = (props) =>
{
    const slider = React.useRef();
    let [open, setOpen] = React.useState("");
    let [show, setShow] = React.useState(false)
    let [fullWidth, setFullWidth] = React.useState(null)
    React.useEffect(() =>
    {
        let timeout;
        if (!show)
        {
            if (timeout)
            {
                clearTimeout(timeout)
            }
            timeout = setTimeout(() =>
            {
                setOpen("")
                setShow(true)
                setFullWidth(null)
            }, 300);
        }

        return () =>
        {
            clearTimeout(timeout);
        }
    }, [!show])

    function toggleHandler(image, fullWidth)
    {
        if (fullWidth)
        {
            setFullWidth(fullWidth);
        }
        if (image)
        {
            setOpen(image);
            setShow(true)
        } else
        {
            setShow(false)
        }
    }

    let isDown = false;
    let startX;
    let scrollLeft;

    const onMouseUp = (event) =>
    {
        isDown = false;
    }

    const onMouseDown = (event) =>
    {
        isDown = true;
        startX = event.pageX - (+slider.current.style.marginLeft.slice(0, -2));
        scrollLeft = +slider.current.style.marginLeft.slice(0, -2);
    }

    const onMouseLeave = (event) =>
    {
        isDown = false;
        document.body.style.overflow = 'auto';
    }

    const onMouseMove = (event) =>
    {
        document.body.style.overflow = 'hidden';
        if (!isDown) return;
        event.preventDefault();
        animate(event);
    }

    function onResize()
    {

    }

    const onTouchStart = (event) =>
    {
        isDown = true;
        startX = event.targetTouches[0].pageX - (+slider.current.style.marginLeft.slice(0, -2));
        scrollLeft = +slider.current.style.marginLeft.slice(0, -2);
    }

    const onTouchMove = event =>
    {
        if (!isDown) return;
        event.preventDefault();
        animate(event, "touch");
    }

    const animate = (event, parent) =>
    {
        let cardCount = document.querySelectorAll(".card-main").length;
        let parentWidth = slider.current.parentNode.getBoundingClientRect().width;
        let sliderWidth = slider.current.getBoundingClientRect().width;
        let rightLim = Math.ceil(sliderWidth - parentWidth);
        const x = (parent === "touch" ? event.targetTouches[0].pageX : event.pageX) - +slider.current.style.marginLeft.slice(0, -2);
        const walk = x - startX;
        let marginLeft = slider.current.style.marginLeft.slice(0, -2);

        if (parent === "wheel")
        {
            const delta = Math.sign(event.deltaY);
            scrollLeft = ((-delta * (rightLim / cardCount)) + +slider.current.style.marginLeft.slice(0, -2));
        }
        else
        {
            scrollLeft = (+marginLeft + (walk * 2 + 70))
        }

        if (parentWidth > sliderWidth)
        {
            requestAnimationFrame(() =>
            {
                slider.current.style.marginLeft = `${0}px`
            })
        }
        else if (scrollLeft < -rightLim)
        {
            requestAnimationFrame(() =>
            {
                slider.current.style.marginLeft = `${-rightLim}px`
            })
        }
        else if (scrollLeft > 0)
        {
            requestAnimationFrame(() =>
            {
                slider.current.style.marginLeft = `${0}px`
            })
        }
        else
        {
            requestAnimationFrame(() =>
            {
                slider.current.style.marginLeft = `${scrollLeft}px`
                let percent = Math.ceil(-scrollLeft * 100 / rightLim);
                document.querySelector(".mini-line").style.width = `${percent}%`
            })
        }
    }

    const toImage = (value) =>
    {
        let parentWidth = slider.current.parentNode.getBoundingClientRect().width;
        let sliderWidth = slider.current.getBoundingClientRect().width;
        let rightLim = Math.ceil(sliderWidth - parentWidth);
        if (+value === 1)
        {
            slider.current.style.marginLeft = `-${rightLim / 2}px`
            document.querySelector(".mini-line").style.width = "50%"
        } else if (+value === 2)
        {
            slider.current.style.marginLeft = `-${rightLim}px`
            document.querySelector(".mini-line").style.width = "100%"
        } else
        {
            slider.current.style.marginLeft = "0px";
            document.querySelector(".mini-line").style.width = "0%"
        }
    }

    const onMouseWheel = (event) =>
    {
        animate(event, "wheel");
    }

    React.useEffect(() =>
    {
        slider.current.addEventListener("touchstart", onTouchStart);
        slider.current.addEventListener("touchmove", onTouchMove);
        slider.current.addEventListener("touchleave", onMouseLeave);
        slider.current.addEventListener("touchend", onMouseUp);
        slider.current.addEventListener("wheel", onMouseWheel);
        slider.current.addEventListener("mouseup", onMouseUp);
        slider.current.addEventListener("mousedown", onMouseDown);
        slider.current.addEventListener("mouseleave", onMouseLeave);
        slider.current.addEventListener("mousemove", onMouseMove);
        window.addEventListener("resize", onResize);
        return () =>
        {
            window.removeEventListener("resize", onResize);
            slider.current.removeEventListener("touchstart", onTouchStart);
            slider.current.removeEventListener("touchmove", onTouchMove);
            slider.current.removeEventListener("touchleave", onMouseLeave);
            slider.current.removeEventListener("touchend", onMouseUp);
            slider.current.removeEventListener("wheel", onMouseWheel);
            slider.current.removeEventListener("mouseup", onMouseUp);
            slider.current.removeEventListener("mousedown", onMouseDown);
            slider.current.removeEventListener("mouseleave", onMouseLeave);
            slider.current.removeEventListener("mousemove", onMouseMove);
        }
    }, []);

    return (
        <>
            <div className="slider">
                <div className="slider-main">
                    <div className="slider-body" ref={slider} style={{ transition: `margin 0.9s ease-out`, marginLeft: "0" }}>
                        {props.cards.map((item, index) => (
                            <Card toggleHandler={toggleHandler} page={props.page} key={index} data={item} />
                        ))}
                    </div>
                </div>
                <div className="flex-between slider-pages">
                    <MiniView toImage={toImage} data={props.cards} />
                    <div className="scroll-icon"><span>scroll</span> <img src={props.page === "works" ? whiteArrow : arrow} alt="" /> </div>
                </div>
            </div>
            {open &&
                <Animated inverse={!show} from={{ opacity: "0", }} to={{ opacity: "1", transform: "scale(1)" }}>
                    {
                        styles => <div style={styles} onClick={e => setShow(false)} className="full-image" >
                            <Animated inverse={!show} from={{ transform: "scale(0)" }} to={{ transform: "scale(1)" }}>
                                {
                                    sty =>
                                        <div onClick={e => e.stopPropagation()} style={fullWidth ? { ...sty, width: `${fullWidth}vh` } : { ...sty }} className="image-container">
                                            <i onClick={e => toggleHandler("")} className="icon-close" />
                                            <Img image={open} />
                                        </div>
                                }
                            </Animated>
                        </div>
                    }
                </Animated>
            }
        </>
    );

}

export default Slider;