import * as React from 'react'

function MiniView(props)
{

    const toImage = (e) =>
    {
        props.toImage(e.currentTarget.id)
    }
    return (
        <div className="mini-select">
            <div className="mini-line" />
            <div className="mini-line-dotted"></div>
            {
                new Array(1, 2, 3).map((item, index) =>
                    <span key={index} id={index} onClick={toImage} className={`miniView miniView${index}`} key={index} />)
            }
        </div>
    )
}

export default MiniView;