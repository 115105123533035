import * as React from 'react'
import Img from "../defaults/image"
import PortfolioImage from '../portfolio/pImages';
import ReactPlayer from 'react-player'

const Card = (props) =>
{
    const { image, title, text, width, heading, headText, type, url, playing, fullWidth } = props.data;
    const { page } = props;

    function openHandler(image)
    {

        props.toggleHandler(image, fullWidth)
    }

    return (
        <div className={heading ? "card-main flexed" : "card-main"}>
            {
                heading &&
                <div className="wText" style={{ width: "37vw", marginRight: "10%" }}>
                    <h1>{heading}</h1>
                    <span>{headText}</span>
                </div>
            }
            <div>
                <div className="slider-images" style={{ maxWidth: `${width}px`, width: "40vw" }}>
                    {
                        type === "video" && page === "portfolio" ?
                            <ReactPlayer
                                height="100%"
                                width="100%"
                                playing={playing}
                                loop={true}
                                muted={true}
                                controls={true}
                                url={url}
                            /> : page === "portfolio" ? <PortfolioImage image={image} /> :
                                <div style={{ height: "100%" }} onClick={e => openHandler(image)}>
                                    <Img image={image} />
                                </div>
                    }
                </div>
                <div className="card-text">
                    <h3>{title}</h3>
                    <span className="text-span">{text}</span>
                </div>
            </div>
        </div>
    );
}

export default Card;
