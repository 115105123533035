import React from 'react';
import Slider from "../slider/slider"
import Cards from "../works/workCards"

const ContentMain = () => {
    return (
        <div className="portfolio-body">
            <Slider page="works" cards={Cards} />
        </div>
    );
}

export default ContentMain;