import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const PortfolioImage = (props) => (
  <StaticQuery
    query={graphql`
      query {

        p1: file(relativePath: { eq: "portfolio/1.jpg" }) {
          childImageSharp {
            fluid(quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        p2: file(relativePath: { eq: "portfolio/2.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }

          p3: file(relativePath: { eq: "portfolio/3.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }

          p4: file(relativePath: { eq: "portfolio/4.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p5: file(relativePath: { eq: "portfolio/5.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p6: file(relativePath: { eq: "portfolio/6.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p7: file(relativePath: { eq: "portfolio/7.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p8: file(relativePath: { eq: "portfolio/8.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p9: file(relativePath: { eq: "portfolio/9.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p10: file(relativePath: { eq: "portfolio/10.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p11: file(relativePath: { eq: "portfolio/11.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p12: file(relativePath: { eq: "portfolio/12.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p13: file(relativePath: { eq: "portfolio/13.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p14: file(relativePath: { eq: "portfolio/14.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p15: file(relativePath: { eq: "portfolio/15.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p16: file(relativePath: { eq: "portfolio/16.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p17: file(relativePath: { eq: "portfolio/17.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p18: file(relativePath: { eq: "portfolio/18.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p19: file(relativePath: { eq: "portfolio/19.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p20: file(relativePath: { eq: "portfolio/20.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p21: file(relativePath: { eq: "portfolio/21.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p22: file(relativePath: { eq: "portfolio/22.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p23: file(relativePath: { eq: "portfolio/23.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p24: file(relativePath: { eq: "portfolio/24.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p25: file(relativePath: { eq: "portfolio/25.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p26: file(relativePath: { eq: "portfolio/26.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p27: file(relativePath: { eq: "portfolio/27.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          p28: file(relativePath: { eq: "portfolio/28.jpg" }) {
            childImageSharp {
              fluid(quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }


      }
    `}
    render={data => <Img fluid={data[props.image].childImageSharp.fluid} />}
  />
)
export default PortfolioImage
