import React from "react"
import Layout from "../components/defaults/layout"
import SEO from "../components/defaults/seo";
import "../style/portfolio/portfolio.scss"
import ContentMain from "../components/works/worksContent";

function PortfolioPage() {
    return (<Layout>
        <SEO title="Art Works" keywords={[`Navasardi`, `Arsen Navasardi`, `Navasardi Art Studio`, `Navasardi Art Studio Works`, `Art Studio Yerevan`, `Art Studio Armenia`, `Art Works`, `Navasardi Works`, `Arsen Navasardi Works`,]} />
        <ContentMain />
    </Layout>)
}


export default PortfolioPage