const Cards = [
    {
        width: 540,
        image: "w1",

        heading: "My Art Works",
        headText: "Autobiographical and dreamlike, my art believes in the infinite potential of the human mind, thus adhering to the classic truths and putting the individual first.",

    },
    {
        width: 515,
        image: "w2",
        text: "Oil on Canvas",
        title: "Action",
        type: "fixed",
        fullWidth: 70,
    }, {
        width: 351,
        image: "w3",
        text: "Oil on Canvas",
        title: "Enlightenment",
        type: "fixed"
    },
    {
        width: 450,
        image: "w4",
        text: "Oil on Canvas",
        title: "Citizen",
        type: "fixed"
    },
    {
        width: 420,
        image: "w5",
        text: "Oil on Canvas",
        title: "No title",

    },
    {
        width: 396,
        image: "w6",
        text: "Oil on Canvas",
        title: "With vanishing spring, facing new blossom the hero of the tale surrounded by purified gardens from the memories of the past",
    },
    {
        width: 387,
        image: "w7",
        text: "Oil on Canvas",
        title: "Unedited letter to a soulmate",
    },
    {
        width: 509,
        image: "w8",
        text: "Drawing",
        title: "Spring",
        fullWidth: 70
    },
    {
        width: 364,
        image: "w9",
        text: "Drawing",
        title: "Waiting for Michelle",
    },
    // {
    //     width: 600,
    //     image: "w10",
    //     text: "Drawing",
    //     title: "Discovery",
    // },
    // {
    //     width: 421,
    //     image: "w11",
    //     text: "Drawing",
    //     title: "New expectations",
    // },
    {
        width: 430,
        image: "w12",
        text: "Drawing",
        title: "Fishy lady",
    },
    // {
    //     width: 395,
    //     image: "w13",
    //     text: "Drawing",
    //     title: "Female",
    // },
    {
        width: 580,
        image: "w14",
        text: "Mixed media",
        title: "Prophets",
        fullWidth: 80,

    },
    {
        width: 350,
        image: "w15",
        text: "Mixed media",
        title: "Acting",
    },
    {
        width: 640,
        image: "w16",
        text: "Mixed media",
        title: "Elusive emotions",
        fullWidth: 80,

    },
    // {
    //     width: 526,
    //     image: "w17",
    //     text: "Mixed media",
    //     title: "Actor",
    // },
    {
        width: 597,
        image: "w18",
        text: "Mixed media",
        title: "The passengers",
        fullWidth: 75,
    },
]

export default Cards
